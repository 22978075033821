import React, { Component } from 'react'
import poo from "../src/img/pp.jpg"
import mm from "../src/img/18939.jpg"
import { Paper,Box,Link, Checkbox } from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Container,Typography,Card,Grid,Divider,TextField,Button} from '@mui/material';
import bb from "../src/img/bb.webp"
import moment from 'moment';
import p1 from "../src/img/p1.webp"
import p2 from "../src/img/p2.webp"
import p3 from "../src/img/p3.webp"
import base_url from './base_url'

import map2 from "../src/img/map2.png"
const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" sx={{fontSize:14,color:'#c7167a',}}>
      Home
    </Link>,
   
    <Typography key="3" color="text.primary" sx={{fontSize:14,color:'#c7167a'}}>
    GetFreeTrialForm
    </Typography>,
  ];

export class GetFreeTrialForm extends Component {

  constructor(props) {
    super(props)
  
    this.state = {
       name : "",
       email  :"",
       phone_no : "",
       date  : "",
       experiance  :"",
       trading_view_account_no  :'',

       cash : false,
       cash_option  :false,
       cash_option_future : false,
      
    }
    this.handleChange=this.handleChange.bind(this);
  
  }

    
    
    handleChange=(e)=>{
    this.setState({[e.target.name]:e.target.value})
    }
    

sendInquires=()=>{
  if( this.state.name!==""&&this.state.email!==""&&this.state.phone_no!==""&&this.state.date!==""&&this.state.experiance!==""&&this.state.trading_view_account_no!==''){
   fetch(`${base_url.base_url}/sendTrialInquiry`, {
    headers: {
      "Content-Type": "application/json",
    },
    method: "post",
    body:JSON.stringify({
      name : this.state.name,
      email  : this.state.email,
      phone_no : this.state.phone_no,
      date  : this.state.date,
      experiance  : this.state.experiance,
      trading_view_account_no  : this.state.trading_view_account_no,
      cash : this.state.cash,
      cash_option  : this.state.cash_option,
      cash_option_future : this.state.cash_option_future,
    })
  }).then((res) => {
      return res.json();
    })
    .then((result) => {
  alert('We will soon connect to you');
  this.setState({
    name : "",
    email  :"",
    phone_no : "",
    date  : "",
    experiance  :"",
    trading_view_account_no  :'',
    cash : false,
    cash_option  :false,
    cash_option_future : false,
  })
    })
  }else{
    alert('Fill All Fields')
  }

}


  render() {
    return (
      <div>
    
<br/>
<br/>
<br/>
 <Box sx={{backgroundImage: `url(${map2})`,minHeight:300,backgroundColor:'#022246 ',opacity:0.9}}>
 <Box sx={{paddingLeft:{xs:0,sm:3},paddingRight:{xs:0,sm:3},borderRadius:1}}>
 <Container maxWidth='xl'>
 <br/>
 <Grid container spacing={2}>
 <Grid item xs={12} sm={12}>
 <Box sx={{backgroundColor:'white',minHeight:400,display:'flex',flexDirection:'column',marginLeft:{xs:'2%',sm:'25%',md:'35%'},mr:{xs:'2%',sm:'25%',md:'35%'},borderRadius:1}}>
 <Box sx={{display:'flex',flexDirection:'column',marginLeft:{xs:'0',sm:'0',md:'4%',},marginRight:{xs:'0',sm:'0',md:'4%',}}}>
 <br/>
 <Typography align='center' sx={{m:2,fontWeight:'bold',color:'#004285'}}>Apply For Free Trial Demo</Typography>
 <Divider/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Enter Name</Typography>
 <TextField InputProps={{style:{fontSize:12}}}  value={this.state.name}  onChange={this.handleChange} id="outlined-basic" placeholder="Name" name='name' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Enter Email Id</Typography>
 <TextField InputProps={{style:{fontSize:12}}} value={this.state.email} onChange={this.handleChange} id="outlined-basic" placeholder="Enter email id" name='email' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Enter Phone No</Typography>
 <TextField InputProps={{style:{fontSize:12}}} value={this.state.phone_no} onChange={this.handleChange} id="outlined-basic" placeholder="Enter phone no" name='phone_no' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Date of Free Trial You Want</Typography>
 <TextField InputProps={{style:{fontSize:12}}}  type='date' onChange={this.handleChange} id="outlined-basic" placeholder="Which date of free trial you want " name='date' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>Exprience In TradingView(Years)</Typography>
 <TextField InputProps={{style:{fontSize:12}}} value={this.state.experiance} onChange={this.handleChange} id="outlined-basic" placeholder="Exprience in trading" name='experiance' variant="outlined"  size='small' sx={{ml:1,mr:1,fontSize:10}}/>
 <Typography sx={{fontSize:12,mt:1,ml:1,mb:0.5,fontWeight:'500'}}>TradingView Account No</Typography>
 <TextField InputProps={{style:{fontSize:12}}} value={this.state.trading_view_account_no} onChange={this.handleChange} id="outlined-basic" placeholder="Tradingview account no" name='trading_view_account_no' variant="outlined"  size='small'sx={{ml:1,mr:1,fontSize:10}}/>



<Box sx={{display:'flex',flexDirection:'row',mt:2}}>
<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}>
<Checkbox size='small' checked={this.state.cash} onClick={()=>this.setState({
  cash:true,
  cash_option  :false,
  cash_option_future : false,
  })}/>
<Typography sx={{fontSize:13}}>Cash</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',ml:2}}>
<Checkbox size='small' checked={this.state.cash_option} 
onClick={()=>this.setState({
  cash:false,
  cash_option  :true,
  cash_option_future : false,
  })}
/>
<Typography sx={{fontSize:13}}>Future & Option</Typography>
</Box>

<Box sx={{display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',ml:2}}>
<Checkbox size='small' checked={this.state.cash_option_future}
onClick={()=>this.setState({
  cash:false,
  cash_option  : false,
  cash_option_future : true,
  })}
/>
<Typography sx={{fontSize:13}}>Cash & Future and Option</Typography>
</Box>

</Box>


<br/>


 <Button variant="contained" onClick={this.sendInquires} sx={{width:200,backgroundColor:'green',ml:2}}> submit </Button>


 <br/>
 <br/>
 </Box> 
   



   </Box>
 </Grid>
</Grid>

</Container>
</Box>
<br/>
 </Box>
 
 





      
      </div>
    )
  }
}

export default GetFreeTrialForm


